// возможные значения redirect_type: 'static', 'dynamic', '' (пустая строка по факту === 'static' и нужна для совместимости https://wimark.kaiten.ru/34477225)
const getRedirectTypeCellData = (redirectTtype) => {
  if (redirectTtype === 'static' || redirectTtype === '') {
    return 'static';
  }
  return redirectTtype;
};

export {
  getRedirectTypeCellData
};
