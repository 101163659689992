var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('WTable',{staticStyle:{"height":"calc(70vh)"},attrs:{"max-height":"calc(70vh - 50px)","columns":_vm.columns,"rows":_vm.rows,"isDisabled":_vm.isDisabled,"bordered":false,"withToggleColumnVisibilityMode":!_vm.isOneColMode,"line-numbers":!_vm.isOneColMode,"row-style-class":_vm.rowStyleClassFn,"search-options":{
    enabled: true,
    skipDiacritics: true,
    placeholder: ("" + (_vm.$t('general.search'))),
    externalQuery: _vm.searchQuery
  },"pagination-options":{
    enabled: false, // isOneColMode // info: отключена из-за неовместимости с легаси
    remoteMode: true,
    mode: 'pages', // pages, records
    position: 'bottom',
    perPage: 10,
    perPageDropdownEnabled: true,
    perPageDropdown: [5, 10, 20, 40, 50],
    dropdownAllowAll: true,
    jumpFirstOrLast : true,
    firstLabel: _vm.$t('general.pagination.firstPage'),
    lastLabel: _vm.$t('general.pagination.lastPage'),
    nextLabel: _vm.$t('general.pagination.nextPage'),
    prevLabel: _vm.$t('general.pagination.prevPage'),
    rowsPerPageLabel: _vm.$t('general.pagination.rowsPerPage'),
    pageLabel: _vm.$t('general.pagination.page'),
    ofLabel: _vm.$t('general.pagination.of'),
    allLabel: _vm.$t('general.pagination.all')
  },"isLoading":_vm.isDataLoading},on:{"update:isLoading":function($event){_vm.isDataLoading=$event},"update:is-loading":function($event){_vm.isDataLoading=$event},"update:columns":_vm.handleUpdateColumns,"on-row-click":_vm.handleRowClick,"on-page-change":_vm.handlePageChange,"on-per-page-change":_vm.handlePerPageChange,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-menu",fn:function(){return [_vm._t("table-menu")]},proxy:true},{key:"table-row",fn:function(props){return [(props.column.field === 'redirectType')?[(props.row.redirectType === 'static')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('GuestControl.redirectType.static'))+" ")]):(props.row.redirectType === 'dynamic')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('GuestControl.redirectType.dynamic'))+" ")]):_c('span',[_vm._v("-")])]:[_vm._v(" "+_vm._s(props.formattedRow[props.column.field] ? props.formattedRow[props.column.field] : '-')+" ")]]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }