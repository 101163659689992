<template>
  <WModalContent danger
    :isOpen="isOpen"
    @close="handleClose"
  >
    <template #header>
      <h4 class="heading">
        {{ $t('GuestControl.deleteRedirectRuleModalTitle') }}
      </h4>
    </template>
    <template #body>
      <h6 class="subheading">
        {{ $t('GuestControl.deleteRedirectRuleModalText') }}
        <b>{{ redirectName }}</b>?
      </h6>
    </template>
    <template #footer>
      <div class="button-section">
        <WButton secondary outline @click="handleClose">
          {{ $t('general.cancel') }}
        </WButton>
        <WButton danger outline @click="handleConfirm">
          {{ $t('general.yesDelete') }}
        </WButton>
      </div>
    </template>
  </WModalContent>
</template>

<script>
/**
 * компонент - модалка для подтверждения удаления правила redirect.
 * компонент локальный: используется только в GuestControl.vue
 * @component
 */

export default {
  name: 'DeleteRedirectRuleModal',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    redirectName: {
      type: String,
      required: true
    }
  },
  methods: {
    handleConfirm() {
      this.$emit('confirm');
    },
    handleClose() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="css" scoped>
  h4, h6 {
    margin: 0;
  }

  .button-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  }
</style>
